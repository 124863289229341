<template>
  <div class="myApplication content">
    <div class="contentbox">
      <TableQuery
        :formList="formList"
        :formInline="formInline"
        :customArrKey="customArrKey"
        :btnList="btnList"
        :property="queryProperty"
        @btnClickFun="btnClickFun"
        @moveQueryFun="queryBackFun"
        @formDataFun="formDataFun"
      >
      </TableQuery>
      <div class="p-t-10"></div>
      <div ref="tableBox">
        <SuperTable
          ref="mytable"
          :tableData="tableData"
          :loading="tableLoading"
          :property="tableProperty"
          :pageData="pageData"
          @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
          @operationClick="operationClick"
        >
          <template v-slot:result="slotProps">
            <div class="container red" v-if="slotProps.props.result == '1'">
              审核不通过
            </div>
            <div
              class="container green"
              v-else-if="slotProps.props.result == '2'"
            >
              审核通过
            </div>
            <div class="container" v-else>待审核</div>
          </template>
        </SuperTable>
      </div>
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        v-if="dialogVisible"
        :before-close="dialogClose"
        width="960px"
      >
        <component
          :is="component"
          :popupData="popupData"
          @dialogClose="dialogClose"
        ></component>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import configBasics from "@/utils/configBasics";
import TableQuery from "@/common/module/TableQuery";
import SuperTable from "@/common/module/SuperTable";
import myApplicationDetail from "./myApplicationDetail";
export default {
  name: "allVideo",
  components: {
    TableQuery,
    SuperTable,
    myApplicationDetail
  },
  data() {
    return {
      ops: configBasics.vueScrollOps,
      formList: [
        {
          clearable: true,
          datetype: "datetime",
          endplaceholder: "请选择结束值",
          format1: "yyyy-MM-dd HH:mm:ss",
          format: "yyyy-MM-dd HH:mm:ss",
          showhide: true,
          label: "申请时间：",
          valueformat1: "yyyy-MM-dd HH:mm:ss",
          type: "DatePickerScope",
          startname: "starttime",
          startplaceholder: "请选择起始值",
          characteristic: "26196aa2-8223-4271-9f6c-4718dwsdssdsd",
          valueformat: "yyyy-MM-dd HH:mm:ss",
          pickeroptionsend:
            "pickerOptionsEnd26196aa2-8223-4271-9f6c-4718dwsdssdsd",
          name: "choosetime",
          width: "530px",
          endname: "endtime",
          disabled: false,
          control: true,
          noDisabledDate: true
        },
        {
          name: "applytypecode",
          label: "申请类型",
          showhide: true,
          type: "selectst",
          clearable: true,
          placeholder: "请选择申请类型",
          option: []
        },
        {
          name: "videoname",
          label: "视频名称",
          showhide: true,
          type: "text",
          clearable: true,
          placeholder: "请输入视频名称"
        }
      ],
      formInline: {
        choosetime: ["", ""],
        applytypecode: "",
        videoname: ""
      },
      customArrKey: ["choosetime"],
      btnList: [
        {
          name: "searchButton",
          icon: "icon-tianjia",
          label: "查询",
          type: "searchButton"
        }
      ],
      queryProperty: {
        labelWidth: "120px",
        typeName: "cedian",
        moveLabelWidth: "120px"
      },
      /* 表格数据 */
      tableData: {
        listData: [],
        titleData: [],
        btnData: []
      },
      /* 表格加载 */
      tableLoading: false,
      /* 表格属性 */
      tableProperty: {
        typeName: "",
        emptyText: "",
        fixheight: false,
        isOrder: true, // 设置表格有序号
        // border: true, // 设置表格有边框
        operationWidth: "140px"
      },
      /* 分页 */
      pageData: {
        currentPage: 1,
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0
      },
      loading: false,
      btnAuthority: false,
      popupData: {},
      dialogVisible: false,
      title: "",
      component: ""
    };
  },
  created() {
    this.findByDomainid();
    this.resetArr();
    this.getTableList(); // 表格列表数据
  },
  methods: {
    findByDomainid() {
      const params = {
        domainid: "808"
      };
      this.$api("common.domain")
        .invoke(params)
        .then(({ data }) => {
          if (data.code === 1) {
            if (data.data) {
              data.data.map(item => {
                item.labelname = item.name;
                item.value = item.code;
              });
              data.data.unshift({
                labelname: "全部",
                value: ""
              });
              this.formList.map(item => {
                if (item.name === "applytypecode") {
                  item.option = data.data;
                }
              });
            }
          }
        })
        .catch(() => {});
    },
    resetArr() {
      this.tableData.titleData = [
        {
          headeralign: "center",
          prop: "applytime",
          showoverflowtooltip: true,
          showhide: true,
          label: "申请时间",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "applytypename",
          showoverflowtooltip: true,
          showhide: true,
          label: "申请类型",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "videotypename",
          showoverflowtooltip: true,
          showhide: true,
          label: "视频类型",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "videoname",
          showoverflowtooltip: true,
          showhide: true,
          label: "视频名称",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "memo",
          showoverflowtooltip: true,
          showhide: true,
          label: "视频简介",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "checkusername",
          showoverflowtooltip: true,
          showhide: true,
          label: "审核人",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "checktime",
          showoverflowtooltip: true,
          showhide: true,
          label: "审核时间",
          align: "center"
        },
        {
          headeralign: "center",
          // prop: "result",
          name: "result",
          showoverflowtooltip: true,
          showhide: true,
          type: "slot",
          label: "审核结果",
          align: "center"
        }
      ];
      this.btnAuthority =
        this.$store.state.operationPower.edit.SHJ_ControllerUnit;
      if (this.btnAuthority) {
        this.btnList = [
          {
            name: "searchButton",
            icon: "icon-tianjia",
            label: "查询",
            type: "searchButton"
          }
        ];
        this.tableData.btnData = [
          {
            name: "detailsButton",
            icon: "icon-video-shenqing",
            label: "申请",
            type: "detailsButton"
          }
        ];
      } else {
        this.btnList = [
          {
            name: "searchButton",
            icon: "icon-tianjia",
            label: "查询",
            type: "searchButton"
          }
        ];
        this.tableData.btnData = [
          {
            name: "detailsButton",
            icon: "iconxiangqing1",
            label: "详情",
            type: "detailsButton"
          }
        ];
      }
    },
    dialogClose() {
      this.dialogVisible = false;
    },
    getTableList() {
      const params = {
        begintime: this.formInline.choosetime[0] || "",
        endtime: this.formInline.choosetime[1] || "",
        videoname: this.formInline.videoname,
        page: this.pageData.currentPage,
        rows: this.pageData.pageSize
      };
      console.log("params", params);
      this.$api("videoApproval.getMyVideoApplyList")
        .invoke(params)
        .then(({ data: { code, data } }) => {
          if (code === 1) {
            if (data) {
              this.tableData.listData = data.data;
              this.pageData.total = data.total;
            }
          } else {
            this.$message({
              showClose: true,
              message: data,
              type: "error",
              duration: 1000
            });
          }
        });
    },
    /* 当前页-改变事件 */
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.getTableList();
    },
    /* 每页多少条-改变事件 */
    handleSizeChange(val) {
      this.pageData.currentPage = 1;
      this.pageData.pageSize = val;
      this.getTableList();
    },
    // 表格列表上的点击事件
    operationClick(item, scope) {
      if (item.name === "detailsButton") {
        this.dialogVisible = true;
        this.title = "申请详情";
        this.popupData = {
          result: scope.row.result,
          pkId: scope.row.pkId
        };
        this.component = "myApplicationDetail";
      }
    },
    // 按钮点击事件
    btnClickFun(item) {
      if (item.name === "searchButton") {
        // 查询
        this.pageData.currentPage = 1;
        this.getTableList();
      }
    },
    // 回车查询
    queryBackFun(formInline) {
      this.formInline = formInline;
      // 查询
      this.pageData.currentPage = 1;
      this.getTableList();
    },
    // 查询标签点击事件
    formDataFun(formInline) {
      this.formInline = formInline;
    }
  }
};
</script>
<style scoped lang="less">
.myApplication {
  .container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    line-height: 44px;
    font-size: 14px;
    &.red {
      background: #f40000;
      color: #fff;
    }
    &.green {
      background: #50cc21;
      color: #fff;
    }
  }
}
</style>
